import * as React from "react";

const IndexPage = () => {
  return (
    <main>
      <title>B&J Sewing Attachments</title>
      <div className="bg-gray-50 h-screen">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center text-center lg:text-left lg:justify-between h-screen">
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">B&J Sewing Attachments</span>
              <span className="block text-indigo-600 text-2xl">
                Sorry, we're closed.
              </span>
            </h2>
          </div>
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-10 text-center lg:text-right lg:mt-0">
              <span className="block">B&J Attachement de Couture</span>
              <span className="block text-indigo-600 text-2xl">
                Désolé, nous sommes fermés.
              </span>
            </h2>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
